import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { AUTH_ROUTES } from './shared/routes/content-layout.routes';
import { AuthGuardService } from '@services/auth-guard.service';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { RoleGuardService } from '@services/role-guard.service';
import { ADMIN_DASHBOARD_ROUTES, SUADMIN_DASHBOARD_ROUTES, VENDOR_DASHBOARD_ROUTES, STORE_DASHBOARD_ROUTES } from './shared/routes/dashboard-layout.routes';
import { ResetpasswordComponent } from './auth/resetpassword/resetpassword.component';
import { InvoicesComponent } from './template/invoices/invoices.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    component: ContentLayoutComponent,
    data: { title: 'Auth Views' },
    children: AUTH_ROUTES,
  },
  {
    path: 'store',
    canActivate: [RoleGuardService],
    canActivateChild: [RoleGuardService],
    component: DashboardLayoutComponent,
    data: { title: 'Dashboard', expectedRole: 'hospital-user' },
    runGuardsAndResolvers: 'always',
    children: STORE_DASHBOARD_ROUTES,
  },
  {
    path: 'customer',
    canActivate: [RoleGuardService],
    canActivateChild: [RoleGuardService],
    component: DashboardLayoutComponent,
    data: { title: 'Dashboard', expectedRole: 'hospital-admin' },
    runGuardsAndResolvers: 'always',
    children: ADMIN_DASHBOARD_ROUTES,
  },
  {
    path: 'suadmin',
    canActivate: [RoleGuardService],
    canActivateChild: [RoleGuardService],
    component: DashboardLayoutComponent,
    data: { title: 'Dashboard', expectedRole: 'super-admin' },
    runGuardsAndResolvers: 'always',
    children: SUADMIN_DASHBOARD_ROUTES,
  },
  {
    path: 'vendor',
    canActivate: [RoleGuardService],
    canActivateChild: [RoleGuardService],
    component: DashboardLayoutComponent,
    data: { title: 'Dashboard', expectedRole: 'vendor' },
    runGuardsAndResolvers: 'always',
    children: VENDOR_DASHBOARD_ROUTES,
  },
  {
    path: 'template/invoice/:id',
    component: InvoicesComponent,
    data: {
      title: 'Invoice',
    }
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
