import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '@services/local-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    public http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  // api call 
  public parseApiCall(url: string, method: string, data: any = {}, options: any = {}): Observable<any> {
    switch (method) {
      case 'GET':
        return this.http.get(url + "?" + new URLSearchParams(data), options).pipe(map((response) => {
          return response;
        }));
      case 'POST':
        return this.http.post(url, data).pipe(map((response) => {
          return response;
        }));
      case 'PUT':
        return this.http.put(url, data).pipe(map((response) => {
          return response;
        }));
      case 'DELETE':
        return this.http.delete(url).pipe(map((response) => {
          return response;
        }));
      default:
        return EMPTY;
    }
  }

  // To close user session
  doLogout() {
    this.localStorageService.removeItem('accessToken');
    this.localStorageService.removeItem('userType');
    this.localStorageService.removeItem('userDetails');
    this.localStorageService.removeItem('customerDetails');
    this.localStorageService.removeItem('vendorDetails');
    this.router.navigate([`/auth/signin`]);
  }
}
