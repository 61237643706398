import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFilterDataItems } from '@models/filter-data';
import { APIResponse } from '@models/api-response-data';
import { ApiService } from '@services/api-service.service';
import { ApiConstants } from '@utils/api-constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss']
})
export class CommonFilterComponent implements OnInit {
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Input() public filterItems!: IFilterDataItems;
  @Input() public searchTerms1!: any;
  customerLists!: any;
  vendorLists!: any;
  filterForm!: FormGroup;
  custom = false
  stores!: any;
  start: any
  selected: any = {
    startDate: new Date(),
    endDate: new Date(),
  };
  public dateFormat: String = 'dd-MM-yyyy';

  public today: Date = new Date(new Date().toDateString());
  public yesterday: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
  );
  public last7Days: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() - 6)).toDateString()
  );
  public last30Days: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() - 29)).toDateString()
  );
  public weekStart: Date = new Date(
    new Date(
      new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
    ).toDateString()
  );
  public weekEnd: Date = new Date(
    new Date(
      new Date().setDate(
        new Date(
          new Date().setDate(
            new Date().getDate() - ((new Date().getDay() + 7) % 7)
          )
        ).getDate() + 6
      )
    ).toDateString()
  );
  public monthStart: Date = new Date(
    new Date(new Date().setDate(1)).toDateString()
  );
  public monthEnd: Date = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)
    ).toDateString()
  );
  public lastStart: Date = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
    ).toDateString()
  );
  public lastEnd: Date = new Date(
    new Date(new Date().setDate(0)).toDateString()
  );
  public yearStart: Date = new Date(
    new Date(
      new Date(
        new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setMonth(
          0
        )
      ).setDate(1)
    ).toDateString()
  );
  public yearEnd: Date = new Date(
    new Date(new Date(new Date().setMonth(0)).setDate(0)).toDateString()
  );

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    if (this.filterItems.fields == undefined) {
      this.filterItems.fields = {
        customerName: false,
        stock: false,
        vendorList: false,
        search: true,
        store: false,
        ved: false,
        abc: false,
        mapType: false,
        primaryvendor: false,
        secondaryvendor: false,
        date: false,
        itemCode: false,
        orderId: false
      }
    } else {
      if (this.filterItems.fields.search == undefined) {
        this.filterItems.fields.search = true;
      }
    }
    this.customerList();
    this.vendorList();
    this.sotreList();
    this.filterForm = this.formBuilder.group({
      searchBy: [''],
      customerId: [''],
      stockLevel: [''],
      vendorId: [''],
      storeId: [''],
      mapType: [''],
      primaryVendor: [''],
      secondaryVendor: [''],
      abc: [''],
      ved: [''],
      dateRange: [''],
      itemCode: [''],
      orderId: [''],
      status: [''],
      startTime: [''],
      endTime: [''],
      date: [[new Date(), new Date()]]
    })
    this.filterForm.valueChanges.subscribe(data => {
      this.start = this.filterForm.value.startTime
      if (this.filterForm.value.dateRange == 'customrange') {
        this.custom = true
        if (this.filterForm.value.startTime && this.filterForm.value.endTime) {
          this.filter.emit(this.filterForm.value)
        }
      }
      else {
        const data: any = {
          searchBy: this.filterForm.value.searchBy,
          customerId: this.filterForm.value.customerId,
          stockLevel: this.filterForm.value.stockLevel,
          vendorId: this.filterForm.value.vendorId,
          storeId: this.filterForm.value.storeId,
          mapType: this.filterForm.value.mapType,
          primaryVendor: this.filterForm.value.primaryVendor,
          secondaryVendor: this.filterForm.value.secondaryVendor,
          abc: this.filterForm.value.abc,
          ved: this.filterForm.value.ved,
          itemCode: this.filterForm.value.itemCode,
          orderId: this.filterForm.value.orderId,
          status: this.filterForm.value.status,
          startAt: null,
          endAt: null
        }
        if (this.filterForm.value.date[0] && this.filterForm.value.date[0]) {
          data.startAt = this.datePipe.transform(new Date(this.filterForm.value.date[0]), 'yyyy-MM-dd 00:00:00')
          data.endAt = this.datePipe.transform(new Date(this.filterForm.value.date[1]), 'yyyy-MM-dd 24:00:00')
        }
        this.filter.emit(data)
      }

    })
  }

  customerList() {
    if (this.filterItems.fields.customerName) {
      this.apiService.parseApiCall(ApiConstants.URL.CUSTOMERS_SELECTLIST.URI, ApiConstants.URL.CUSTOMERS_SELECTLIST.METHOD, {}).subscribe((res: APIResponse) => {
        if (res.success) {
          this.customerLists = res.data.customers;
        }
      });
    }
  }

  vendorList() {
    if (this.filterItems.fields.vendorList || this.filterItems.fields.primaryVendor || this.filterItems.fields.secondaryVendor) {
      this.apiService.parseApiCall(ApiConstants.URL.VENDOR_DROPDOWN_LIST.URI, ApiConstants.URL.VENDOR_LIST.METHOD, {}).subscribe((res: APIResponse) => {
        if (res.success) {
          this.vendorLists = res.data.vendors;
        }
      });
    }
  }

  sotreList() {
    if (this.filterItems.fields.store) {
      this.apiService.parseApiCall(ApiConstants.URL.STORE_DROPDOWN_LIST.URI, ApiConstants.URL.STORE_DROPDOWN_LIST.METHOD, {}).subscribe((res: APIResponse) => {
        if (res.success) {
          this.stores = res.data.stores;
        }
      });
    }
  }

  public onChange(event: any) {
    if (event.startDate && event.endDate) {
      this.selected = {
        startDate: event.startDate || new Date(),
        endDate: event.endDate || new Date(),
      };
    }
  }
}
