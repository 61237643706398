import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { LocalStorageService } from '@services/local-storage.service';
import { ApiService } from '@services/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    public router: Router,
    public auth: AuthenticationService,
    public localStorageService: LocalStorageService,
    private apiService: ApiService
  ) { }

  canActivate(): boolean {
    if (this.auth.isAuthenticated()) {
      const userType: any = this.auth.getUserType();
      switch (userType) {
        case 'hospital-admin':
          this.router.navigate([`/customer`]);
          break;
        case 'hospital-user':
          this.router.navigate([`/store`]);
          break;
        case 'super-admin':
          this.router.navigate([`/suadmin`]);
          break;
        case 'vendor':
          this.router.navigate([`/vendor`]);
          break;
        default:
          this.apiService.doLogout()
          break;
      }
      return false;
    }
    return true;
  }

  canActivateChild(): boolean {
    if (this.auth.isAuthenticated()) {
      const userType: any = this.auth.getUserType();
      switch (userType) {
        case 'hospital-admin':
          this.router.navigate([`/customer`]);
          break;
        case 'hospital-user':
          this.router.navigate([`/store`]);
          break;
        case 'super-admin':
          this.router.navigate([`/suadmin`]);
          break;
        case 'vendor':
          this.router.navigate([`/vendor`]);
          break;
        default:
          this.apiService.doLogout()
          break;
      }
      return false;
    }
    return true;
  }
}
