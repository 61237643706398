import { Component, OnInit } from '@angular/core';
import { ApiService } from '@services/api-service.service';
import { APIResponse } from '@models/api-response-data';
import { ApiConstants } from '@utils/api-constants';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { EventQueueService } from '@services/event-queue.service';
import { AppEventType } from '@models/event.type';
import { getUserBaseRoute } from '@utils/helper';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  notificationsCount: any = 0;
  userType!: string;
  navBackground: string = ''
  portalName = '';
  userName = '';
  portalType = 'Admin Panel';
  constructor(
    private apiService: ApiService,
    private router: Router,
    public auth: AuthenticationService,
    private eventQueue: EventQueueService
  ) { }
  navbarClass: any = {
    'hospital-user': 'nav-stu-admin',
    'hospital-admin': 'nav-cu-admin',
    'vendor': 'nav-vu-admin',
    'super-admin': 'accent'
  }

  ngOnInit(): void {
    this.getUserDetails()
    const userRole: any = this.auth.getUserType();
    this.userType = getUserBaseRoute(userRole);
    this.navBackground = this.navbarClass[userRole]
    this.eventQueue.on(AppEventType.ClickedOnNotification).subscribe(event => this.handleEvent(event.payload));
    this.getNotificationCount();
  }

  doLogout() {
    this.apiService.doLogout()
  }

  getNotificationCount() {
    this.apiService.parseApiCall(ApiConstants.URL.NOTIFICATION_UNREAD_COUNT.URI, ApiConstants.URL.NOTIFICATION_UNREAD_COUNT.METHOD, {
    }).subscribe((res: APIResponse) => {
      if (res.success) {
        this.notificationsCount = res.data.unreadCount;
      }
    });
  }

  loadNotificationPage() {
    const userType = this.auth.getUserType();
    switch (userType) {
      case 'hospital-admin':
        this.router.navigate([`/customer/notifications`]);
        break;
      case 'super-admin':
        this.router.navigate([`/suadmin/notifications`]);
        break;
      case 'hospital-user':
        this.router.navigate(['/store/notifications'])
        break;
      case 'vendor':
        this.router.navigate([`/vendor/notifications`]);
        break;
    }
  }

  handleEvent(data: any) {
    this.apiService.parseApiCall(ApiConstants.URL.NOTIFICATION_MARK_AS_READ.URI + '/' + data._id + '/mark-read', ApiConstants.URL.NOTIFICATION_MARK_AS_READ.METHOD, {
    }).subscribe((res: APIResponse) => {
      if (res.success) {
        this.getNotificationCount();
      }
    });
  }

  getUserDetails() {
    this.apiService.parseApiCall(ApiConstants.URL.GET_PROFILE_INFO.URI,
      ApiConstants.URL.GET_PROFILE_INFO.METHOD, {}).subscribe((res: APIResponse) => {
        if (res.success) {
          this.userName = res.data.user_details.firstName
          if (res.data.user_details.userType == 'vendor') {
            this.portalName = res.data.vendor_details.name
            this.portalType = 'Vendor Panel'
          }
          if (res.data.user_details.userType == 'hospital-admin') {
            this.portalName = res.data.customer_details.name
            this.portalType = 'Customer Panel'
          }
          if (res.data.user_details.userType == 'super-admin') {

          }
        }
      })
  }
}
