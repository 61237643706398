import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFilterDataItems } from '@models/filter-data';
import { APIResponse } from '@models/api-response-data';
import { ApiService } from '@services/api-service.service';
import { ApiConstants } from '@utils/api-constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {

  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Input() public filterItems!: IFilterDataItems;
  filterForm!: FormGroup;
  customerLists!: any;

  selected: any = {
    startDate: new Date(),
    endDate: new Date(),
  };
  public dateFormat: String = 'dd-MM-yyyy';

  public today: Date = new Date(new Date().toDateString());
  public yesterday: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
  );
  public last7Days: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() - 6)).toDateString()
  );
  public last30Days: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() - 29)).toDateString()
  );
  public weekStart: Date = new Date(
    new Date(
      new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
    ).toDateString()
  );
  public weekEnd: Date = new Date(
    new Date(
      new Date().setDate(
        new Date(
          new Date().setDate(
            new Date().getDate() - ((new Date().getDay() + 7) % 7)
          )
        ).getDate() + 6
      )
    ).toDateString()
  );
  public monthStart: Date = new Date(
    new Date(new Date().setDate(1)).toDateString()
  );
  public monthEnd: Date = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)
    ).toDateString()
  );
  public lastStart: Date = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
    ).toDateString()
  );
  public lastEnd: Date = new Date(
    new Date(new Date().setDate(0)).toDateString()
  );
  public yearStart: Date = new Date(
    new Date(
      new Date(
        new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setMonth(
          0
        )
      ).setDate(1)
    ).toDateString()
  );
  public yearEnd: Date = new Date(
    new Date(new Date(new Date().setMonth(0)).setDate(0)).toDateString()
  );

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      reportType: [this.filterItems.value.reportType],
      status: [this.filterItems.value.status],
      date: [[this.filterItems.value.startAt, this.filterItems.value.endAt]],
      customerId: [this.filterItems.value.customerId]
    })
    this.customerList();
    this.filterForm.valueChanges.subscribe(data => {
      const query: any = {
        reportType: this.filterForm.value.reportType,
        startAt: this.datePipe.transform(new Date(this.filterForm.value.date[0]), 'yyyy-MM-dd 00:00:00'),
        endAt: this.datePipe.transform(new Date(this.filterForm.value.date[1]), 'yyyy-MM-dd 23:59:59'),
        customerId: this.filterForm.value.customerId
      }
      if (this.filterForm.value.reportType === 'customerList') {
        query.status = this.filterForm.value.status || 'active'
      }
      this.filter.emit(query);
    })
  }

  customerList() {
    if (this.filterItems.fields.customerName) {
      this.apiService.parseApiCall(ApiConstants.URL.CUSTOMERS_SELECTLIST.URI, ApiConstants.URL.CUSTOMERS_SELECTLIST.METHOD, {}).subscribe((res: APIResponse) => {
        if (res.success) {
          this.customerLists = res.data.customers;
        }
      });
    }
  }
}
