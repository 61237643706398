import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '@services/loader.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dashboard-cards',
  templateUrl: './dashboard-cards.component.html',
  styleUrls: ['./dashboard-cards.component.scss']
})
export class DashboardCardsComponent implements OnInit {

  @Input() title!: string;
  @Input() value!: number;
  @Input() class!: string;
  @Input() isAmount = false;
  @Input() iconColor!: string;
  @Input() total!: number;
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {}
}
