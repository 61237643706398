import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataExportModalComponent } from '@components/data-export-modal/data-export-modal.component';
import { APIResponse } from '@models/api-response-data';
import { IFilterDataItems, IFilterDataSearchItems } from '@models/filter-data';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { ApiService } from '@services/api-service.service';
import { DownloadDataService } from '@services/download-data.service';
import { ApiConstants } from '@utils/api-constants';
import { exportDataTypes } from '@utils/constants';
import { ModalConfig } from '@models/modal-config';

@Component({
  selector: 'app-list-logs',
  templateUrl: './list-logs.component.html',
  styleUrls: ['./list-logs.component.scss']
})
export class ListLogsComponent implements OnInit {
  list =[]
  page_size: any = 100;
  page_no: any = 0;
  listLength: number = 0;
  isLoading: boolean = false;
  end_at:any
  start_at:any
  downloadLists:any
  @ViewChild('modalDataExportModal') private dataExportModalComponent!: DataExportModalComponent

  filterBy: any = {}
  searchTerms = ['USERS','ORDERS','CUSTOMERS','VENDORS','STORES','STORE_USER','ITEM_MASTER', 'CUSTOMER_ITEM', 'FORMULARY',
  'VENDOR_ITEM', 'RATE_CONTRACTS', 'CUSTOMER_VENDOR_PREFERENCE',
  'CUSTOM_HEADER_MAP', 'VENDOR_RATING']
  searchTerms1 = [ 'CREATE', 'EDIT', 'DELETE','UPLOADED']
  searchTerms3 = [ 'CREATE', 'EDIT', 'DELETE', 'LOGIN','PASSWORD_RESET','UPLOADED']
  searchTerms2 = ['info', 'error' , 'warning']
  filterDataItems: IFilterDataSearchItems = {
    searchPlaceHolder: 'Log Type',
    searchPlaceHolder1: 'Log Action',
    searchPlaceHolder2: 'Log Level'
    
  }
  exportInfo: any = {
    type: exportDataTypes.LOG_LIST
  }
  dataExportConfig: ModalConfig = {
    modalTitle: 'Data Export',
    modalMessage: ''
  };


  columns: MtxGridColumn[] = [
    { header: 'Sl No', field: 'slNo' },
    { header: 'Action', field: 'meta.action' },
    { header: 'Type', field: 'meta.type' },
    { header: 'Level', field: 'level' },
    { header: 'Message', field: 'message' },
    { header: 'Timestamp', field: 'timestamp' },
    {
      header: 'Action',
      field: 'option',
      pinned: 'right',
      right: '0px',
      type: 'button',
      buttons: [
        // {
        //   icon: 'edit',
        //   tooltip: 'Edit',
        //   type: 'icon',
        //   click: (record) => this.loadPage(record, 'edit'),
        // },
         {
          icon: 'remove_red_eye',
          tooltip: 'View Details',
          type: 'icon',
          click: (record) => this.loadPage(record, 'view'),
        }
      ]
    }
  ]

  constructor(private router: Router,private apiService: ApiService,private downloadDataService: DownloadDataService) { }

  ngOnInit(): void {
   this.getLogsList();
  }
  onPageChange(event: any) {
    this.isLoading = true;
    this.page_size = event.pageSize;
    this.page_no = event.pageIndex;
   
    this.getLogsList();
  }
getLogsList(){
  this.apiService.parseApiCall(ApiConstants.URL.LOGS_LIST.URI, ApiConstants.URL.LOGS_LIST.METHOD, {
    page: this.page_no + 1,
    size: this.page_size,
    ...this.filterBy
   
  }).subscribe((res: any) => {
    console.log(res)
    this.isLoading = false;
    if (res.success) {
   this.list = res.logs;

    
    this.listLength=this.list.length
    //this.listLength = res.pagination.total_items
    }
  });
}
loadPage(value: any, type: string) {
  switch (type) {
   
    case 'view':
      this.router.navigate([`/suadmin/logs/view/${value._id}`]);
      break
    default:
      break;
  }

}
filterData(data: any) {
  this.page_no = 0;
  console.log(data.searchByAction)
  console.log(data.searchByType)
  console.log(data.searchByLevel)
  
  if(data.searchByAction&&data.searchByType&&data.searchByLevel){
    this.filterBy.log_level = data.searchByLevel
    this.filterBy.log_type = data.searchByType
    this.filterBy.log_action = data.searchByAction
  
  }
  else if(data.searchByAction&&data.searchByType){
    this.filterBy.log_type = data.searchByType
    this.filterBy.log_action = data.searchByAction
  
  }
  else if(data.searchByLevel&&data.searchByAction){
    this.filterBy.log_level = data.searchByLevel
    this.filterBy.log_action = data.searchByAction
    
  }
  else if(data.searchByLevel&&data.searchByType){
    this.filterBy.log_level = data.searchByLevel
    this.filterBy.log_type = data.searchByType
    
  }
  else if(data.searchByType){
    this.filterBy.log_type = data.searchByType
    
  }
 
  else if(data.searchByAction){
    this.filterBy.log_action = data.searchByAction
    
  }
  else if(data.searchByLevel){
    this.filterBy.log_level = data.searchByLevel
    
  }
  
 
  else{

  }
  
  
  this.getLogsList();
}
downloadLogList(){
  this.apiService.parseApiCall(ApiConstants.URL.LOGS_LIST.URI, ApiConstants.URL.LOGS_LIST.METHOD, {
    page: this.page_no + 1,
    size: this.page_size,
    ...this.filterBy
   
  }).subscribe((res: any) => {
    console.log(res.logs)
    this.isLoading = false;
    if (res.success) {
      this.downloadLists=res.logs;
      for(var i=0;i<this.downloadLists.length;i++){
        this.downloadLists[i].action = this.downloadLists[i].meta.action;
        this.downloadLists[i].type = this.downloadLists[i].meta.type;
      }
    let logList =  this.downloadLists.map((
      {action,type,level,message,timestamp      
      }:any) => ({'Action':action,'Type':type,'Level':level,'Message':message,'Timestamp':timestamp          
      }));
      this.downloadDataService.logs = logList;
      this.exportInfo.type = exportDataTypes.LOG_LIST;
      this.dataExportModalComponent.open();
    }
  });
}

}
