import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { APIResponse } from '@models/api-response-data';
import { ModalConfig } from '@models/modal-config';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api-service.service';
import { ApiConstants } from '@utils/api-constants';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-order-discrepancy',
  templateUrl: './order-discrepancy.component.html',
  styleUrls: ['./order-discrepancy.component.scss']
})
export class OrderDiscrepancyComponent implements OnInit {

  ngbModalRef!: NgbModalRef;
  @Output() success: EventEmitter<boolean> = new EventEmitter();
  @Input() public modalConfig!: ModalConfig
  @Input() public orderData!: any
  @Input() public invoiceId!: any
  @Input() public invoiceDetails!: any
  @ViewChild('orderDiscrepancy') private modalContent!: TemplateRef<OrderDiscrepancyComponent>
  private modalRef!: NgbModalRef
  orderDiscrepancyForm!: FormGroup;
  submitted: boolean = false;

  list = []
  page_size: any = 100;
  page_no: any = 0;
  listLength: number = 0;
  isLoading: boolean = false;
  userType: string = '';
  showButton: boolean = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    public auth: AuthenticationService
  ) { }

  columns: MtxGridColumn[] = [
    { header: 'Sl No', field: 'slNo', width: '50px' },
    { header: 'Item Name', field: 'item.itemDetails.customerItemDescription', width: '300px' },
    { header: 'Type', field: 'type', width: '50px' },
    { header: 'Value', field: 'value', width: '50px' },
    { header: 'Deviation', field: 'deviation', width: '50px' }
  ]

  ngOnInit(): void {
    this.userType = this.auth.getUserType() as any;
    this.orderDiscrepancyForm = this.formBuilder.group({
      orderId: [''],
      invoiceId: [''],
      note: [''],
      customerId: [''],
      vendorId: ['']
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['orderData'] && !changes['orderData'].firstChange) {
      this.orderData = changes['orderData'].currentValue
      this.orderDiscrepancyForm.patchValue({
        orderId: changes['orderData'].currentValue.orderId,
        invoiceId: changes['orderData'].currentValue.invoiceId,
        customerId: changes['orderData'].currentValue.customerId,
        vendorId: changes['orderData'].currentValue.vendorId,
      })
    }
    if (changes['invoiceDetails'] && !changes['invoiceDetails'].firstChange) {
      this.invoiceDetails = changes['invoiceDetails'].currentValue;
    }

    if (changes['invoiceId'] && !changes['invoiceId'].firstChange) {
      this.getInvoiceDiscrepancy(changes['invoiceId'].currentValue);
      this.orderDiscrepancyForm.patchValue({ invoiceId: changes['invoiceId'].currentValue })
    }
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  get f() {
    return this.orderDiscrepancyForm.controls;
  }

  updateDiscrepancy() {
    this.apiService.parseApiCall(ApiConstants.URL.CUSTOMER_ORDER_DISCREPANCY.URI + '/' + this.orderData._id,
      ApiConstants.URL.CUSTOMER_ORDER_DISCREPANCY.METHOD, this.orderDiscrepancyForm.value).subscribe((res: APIResponse) => {
        if (res.success) {
          this.success.emit()
          this.close();
        }
      });
  }

  getInvoiceDiscrepancy(id: string) {
    this.apiService.parseApiCall(ApiConstants.URL.GET_INVOICE_ORDER_DISCREPANCY.URI + '/' + id,
      ApiConstants.URL.GET_INVOICE_ORDER_DISCREPANCY.METHOD).subscribe((res: APIResponse) => {
        if (res.success) {
          this.list = res.data.discrepancy_list
          const unitRateDiscrepancy = this.list.find((e: any) => { return e.type == 'unitRate' });
          const otherDiscrepancy = this.list.find((e: any) => { return e.type == 'quantity' || e.type == 'uom' });
          if (this.userType === 'super-admin' && unitRateDiscrepancy) {
            this.showButton = true;
          } else if (this.userType === 'hospital-admin' && otherDiscrepancy) {
            this.showButton = true;
          }
        }
      });
  }

  onPageChange(event: any) {
    this.isLoading = true;
    this.page_size = event.pageSize;
    this.page_no = event.pageIndex;
  }

  submitDiscrepancy(action: string) {
    this.apiService.parseApiCall(ApiConstants.URL.UPDATE_ORDER_INVOCIE_DISCREPANCY.URI + '/' + this.orderDiscrepancyForm.value.invoiceId,
      ApiConstants.URL.UPDATE_ORDER_INVOCIE_DISCREPANCY.METHOD, { ...this.orderDiscrepancyForm.value, action: action }).subscribe((res: APIResponse) => {
        if (res.success) {
          this.success.emit()
          this.close();
        }
      });
  }

  deleteDiscrepancy() {
    this.apiService.parseApiCall(ApiConstants.URL.DLETE_ORDER_INVOCIE_DISCREPANCY.URI + '/' + this.orderDiscrepancyForm.value.invoiceId,
      ApiConstants.URL.DLETE_ORDER_INVOCIE_DISCREPANCY.METHOD).subscribe((res: APIResponse) => {
        if (res.success) {
          this.success.emit()
          this.close();
        }
      });
  }
}
