import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadDataService {

  public formularyData: any;
  public rateContractData: any;
  public masterData: any;
  public logs: any;
  public customerStockList: any;
  public PraxiaToHspitalList: any;
  public invoiceDetailsList: any;
  public customerStoreList: any;
  public customerMasterItems: any;
  public vendorList: any;
  public customerList: any;
  public customerOrderList: any;
  public vendorOrdersList: any;
  public customerOrderItemList: any;
  public grnDataList: any;
  public customerProductList: any;
  public customerVendorList: any;
  public customerVendorPreferenceList: any
  public customerDailyStockList: any
  public customerPurchaseOrderList: any
  public customerSalesList: any
  public storeVendorList: any
  public storeOrderList: any
  public vendorMasterItems: any;
  public vendorProductList: any;
  public reportData: any;
  constructor() { }
}
