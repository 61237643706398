export const customerType: any = {
    hospital: 'Hospital',
    shop: 'Shop'
}

export const userRoleType: any = {
    'hospital-user': 'Store Admin',
    'hospital-admin': 'Customer Admin'
}

export const dataUploadTypes = {
    INVOICES: 'invoices',
    ITEM_MASTER_DATA: 'itemMasterData',
    ITEM_CUSTOMER_MASTER_DATA: 'itemCustomerMasterData',
    ITEM_CUSTOMER_STOCK_DATA: 'itemCustomerStockData',
    ITEM_CUSTOMER_SALES_DATA: 'itemCustomerSalesData',
    ITEM_CUSTOMER_PURCHASE_DATA: 'itemCustomerPurchaseData',
    CUSTOMER_FORMULARY_DATA: 'customerFormularyData',
    ITEM_CUSTOMER_GRN_DATA: 'itemCustomerGrnData',
    CUSTOMER_RATE_CONTRACTS_DATA: 'itemCustomerRateContractData',
    ORDER_ITEM_CREDIT_DATA: 'creditNote',
    ITEM_VENDOR_MASTER_DATA: 'itemVendorMasterData',
    ORDER_ITEM_DEBIT_NOTE: 'debitNote',
    VENDOR_RATE_CONTRACTS_DATA: 'vendorRateContractData',
}

export const getBaseRoute: any = {
    'hospital-user': 'store',
    'hospital-admin': 'customer',
    'vendor': 'vendor',
    'super-admin': 'suadmin'
}

export const exportDataTypes: any = {
    CUSTOMER_SALES_LIST: 'CUSTOMER_SALES_LIST',
    VENDOR_ORDERS_LIST: 'VENDOR_ORDERS_LIST',
    CUSTOMER_DAILYSTOCK_LIST: 'CUSTOMER_DAILYSTOCK_LIST',
    CUSTOMER_PURCHASEORDER_LIST: 'CUSTOMER_PURCHASEORDER_LIST',
    FORMULARY_DATA: 'FORMULARY_DATA',
    CUSTOMER_ORDERITEM_LIST: 'CUSTOMER_ORDERITEM_LIST',
    RATE_CONTRACT_DATA: 'RATE_CONTRACT_DATA',
    MASTER_PRODUCT_LIST_DATA: 'MASTER_PRODUCT_LIST_DATA',
    CUSTOMER_ITEM_LIST: 'CUSTOMER_ITEM_LIST',
    VENDOR_LIST: 'VENDOR_LIST',
    CUSTOMER_LIST: 'CUSTOMER_LIST',
    LOG_LIST: 'LOG_LIST',
    CUSTOMER_STOCK_LIST: 'STOCK_LIST',
    CUSTOMER_ORDER_LIST: 'CUSTOMER_ORDER_LIST',
    CUSTOMER_STORE_LIST: 'CUSTOMER_STORE_LIST',
    CUSTOMER_PRODUCT_LIST: 'CUSTOMER_PRODUCT_LIST',
    CUSTOMER_VENDOR_LIST: 'CUSTOMER_VENDOR_LIST',
    CUSTOMER_PREFERENCE_VENDOR_LIST: 'CUSTOMER_PREFERENCE_VENDOR_LIST',
    GRN_DATA: 'GRN_DATA',
    ORDER_DETAILS: 'ORDER_DETAILS',
    STORE_VENDOR_LIST: 'STORE_VENDOR_LIST',
    STORE_ORDER_LIST: 'STORE_ORDER_LIST',
    VENDOR_ITEM_LIST: 'VENDOR_ITEM_LIST',
    VENDOR_PRODUCT_LIST: 'VENDOR_PRODUCT_LIST',
    REPORT_DATA: 'REPORT_DATA',
    PRAXIA_TO_HOSPITAL_INVOICE: 'PRAXIA_TO_HOSPITAL_INVOICE',
    UPLOAD_INVOICE_ID: 'UPLOAD_INVOICE_ID',
    SUA_PRINT_INVOICE: 'SUA_PRINT_INVOICE'
}

export const orderPermissions: any = [
    { value: 'generate_po', label: 'Generate PO' },
    { value: 'edit', label: 'Edit Order' },
    { value: 'delete', label: 'Delete Order' },
    { value: 'export_data', label: 'Export Data' },
];

export const productPermissions: any = [
    { value: 'add', label: 'Add Item' },
    { value: 'edit', label: 'Edit Item' },
    { value: 'delete', label: 'Delete Item' },
    { value: 'export_data', label: 'Export Data' },
];

export const stockPermission: any = [
    { value: 'transfer_stock', label: 'Transfer Stock' },
    { value: 'edit', label: 'Edit Stock' },
    { value: 'export_data', label: 'Export Data' },
];

export const storePermission: any = [
    { value: 'add', label: 'Add Store' },
    { value: 'edit', label: 'Edit Store' },
    { value: 'delete', label: 'Delete Store' },
    { value: 'export_data', label: 'Export Data' },
]

export const workFlowItems: any = [
    { value: 'addItem', label: 'Add Item' },
    { value: 'addVendor', label: 'Add Vendor' },
    // { value: 'rateContractUpdate', label: 'Rate Contract Update' },
    { value: 'poApproval', label: 'PO Approval' }
]

export const stateList: any = [
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Dadra and Nagar Haveli', label: 'Dadra and Nagar Haveli' },
    { value: 'Daman and Diu', label: 'Daman and Diu' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Lakshadweep', label: 'Lakshadweep' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Puducherry', label: 'Puducherry' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'West Bengal', label: 'West Bengal' }
];