import { Routes } from '@angular/router';


export const SUADMIN_DASHBOARD_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/super-admin/home/home.module').then(
                (m) => m.HomeModule
            )
    },
    {
        path: 'vendor-management',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/super-admin/vendors/vendor.module').then(
                (m) => m.VendorModule
            )
    },
    {
        path: 'customer-management',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/super-admin/customers/customers.module').then(
                (m) => m.CustomersModule
            )
    },
    {
        path: 'products-management',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/super-admin/products/products.module').then(
                (m) => m.ProductsModule
            )
    },
    {
        path: 'orders-management',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/super-admin/orders/orders.module').then(
                (m) => m.OrdersModule
            )
    },
    {
        path: 'formulary-management',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/super-admin/formulary/formulary.module').then(
                (m) => m.FormularyModule
            )
    },
    {
        path: 'rate-contracts',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/super-admin/rate-contracts/rate-contracts.module').then(
                (m) => m.RateContractsModule
            )
    },
    {
        path: 'logs',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/super-admin/logs/logs.module').then(
                (m) => m.LogsModule
            )
    },
    {
        path: 'reports',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/super-admin/reports/reports.module').then(
                (m) => m.ReportsModule
            )
    },
    {
        path: 'messages',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/common-ui/messages/messages.module').then(
                (m) => m.MessagesModule
            )
    },
    {
        path: 'settings',
        data: { expectedRole: 'super-admin' },
        loadChildren: () =>
            import('../../dashboard/super-admin/settings/settings.module').then(
                (m) => m.SettingsModule
            )
    },
];

export const ADMIN_DASHBOARD_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/home/home.module').then(
                (m) => m.HomeModule
            )
    },
    {
        path: 'vendor-management',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/vendors/vendors.module').then(
                (m) => m.VendorsModule
            )
    },
    {
        path: 'stores-management',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/stores/stores.module').then(
                (m) => m.StoresModule
            )
    },
    {
        path: 'products-management',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/products/products.module').then(
                (m) => m.ProductsModule
            )
    },
    {
        path: 'stocks-management',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/stocks/stocks.module').then(
                (m) => m.StocksModule
            )
    },
    {
        path: 'orders-management',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/orders/orders.module').then(
                (m) => m.OrdersModule
            )
    },
    {
        path: 'reports',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/reports/reports.module').then(
                (m) => m.ReportsModule
            )
    },
    {
        path: 'notifications',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/notifications/notifications.module').then(
                (m) => m.NotificationsModule
            )
    },
    {
        path: 'vendor-preferences',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/vendor-preferences/vendor-preferences.module').then(
                (m) => m.VendorPreferencesModule
            )
    },
    {
        path: 'leadtime',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/timelead/timelead.module').then(
                (m) => m.TimeleadModule
            )
    },
    {
        path: 'data-uploads',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/common-ui/data-uploads/data-uploads.module').then(
                (m) => m.DataUploadsModule
            )
    },
    {
        path: 'messages',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/common-ui/messages/messages.module').then(
                (m) => m.MessagesModule
            )
    },
    {
        path: 'settings',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/settings/settings.module').then(
                (m) => m.SettingsModule
            )
    },
    {
        path: 'vendor-delivery-schedules',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/vendors-delivery-schedule/vendors-delivery-schedule.module').then(
                (m) => m.VendorsDeliveryScheduleModule
            )
    },
    {
        path: 'vendor-items',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/vendors-items/vendors-items.module').then(
                (m) => m.VendorsItemsModule
            )
    },
    {
        path: 'access-approval',
        data: { expectedRole: 'hospital-admin' },
        loadChildren: () =>
            import('../../dashboard/customers/access-approval/access-approval.module').then(
                (m) => m.AccessApprovalModule
            )
    }
];

export const VENDOR_DASHBOARD_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        data: { expectedRole: 'vendor' },
        loadChildren: () =>

            import('../../dashboard/vendor/home/home.module').then(
                (m) => m.HomeModule
            )
    },
    {
        path: 'order',
        data: { expectedRole: 'vendor' },
        loadChildren: () =>

            import('../../dashboard/vendor/order/vendor-order.module').then(
                (m) => m.VendorOrderModule
            )
    },
    {
        path: 'notifications',
        data: { expectedRole: 'vendor' },
        loadChildren: () =>
            import('../../dashboard/notifications/notifications.module').then(
                (m) => m.NotificationsModule
            )
    },
    {
        path: 'messages',
        data: { expectedRole: 'vendor' },
        loadChildren: () =>
            import('../../dashboard/common-ui/messages/messages.module').then(
                (m) => m.MessagesModule
            )
    },
    {
        path: 'settings',
        data: { expectedRole: 'vendor' },
        loadChildren: () =>
            import('../../dashboard/vendor/settings/settings.module').then(
                (m) => m.SettingsModule
            )
    }, {
        path: 'products-management',
        data: { expectedRole: 'vendor' },
        loadChildren: () =>
            import('../../dashboard/vendor/products/products.module').then(
                (m) => m.ProductsModule
            )
    },
    {
        path: 'ratings',
        data: { expectedRole: 'vendor' },
        loadChildren: () =>
            import('../../dashboard/vendor/ratings/ratings.module').then(
                (m) => m.RatingsModule
            )
    },
    {
        path: 'delivery-schedules',
        data: { expectedRole: 'vendor' },
        loadChildren: () =>
            import('../../dashboard/vendor/delivery-schedule/delivery-schedule.module').then(
                (m) => m.DeliveryScheduleModule
            )
    },
    {
        path: 'lead-time',
        data: { expectedRole: 'vendor' },
        loadChildren: () =>
            import('../../dashboard/vendor/lead-time/lead-time.module').then(
                (m) => m.LeadTimeModule
            )
    },
    {
        path: 'reports',
        data: { expectedRole: 'vendor' },
        loadChildren: () =>
            import('../../dashboard/vendor/reports/reports.module').then(
                (m) => m.ReportsModule
            )
    },
];


export const STORE_DASHBOARD_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        data: { expectedRole: 'store' },
        loadChildren: () =>
            import('../../dashboard/stores/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            )
    },

    {
        path: 'store',
        data: { expectedRole: 'store' },
        loadChildren: () =>
            import('../../dashboard/stores/orders/order.module').then(
                (m) => m.OrdersModule
            )
    },
    {
        path: 'settings',
        data: { expectedRole: 'store' },
        loadChildren: () =>
            import('../../dashboard/stores/settings/settings.module').then(
                (m) => m.SettingsModule
            )
    },

    {
        path: 'ItemSubscriptions',
        data: { expectedRole: 'store' },
        loadChildren: () =>
            import('../../dashboard/stores/itemsubscription/itemsubscription.module').then(
                (m) => m.ItemSubscriptionsModule
            )
    },
    {
        path: 'data-uploads',
        data: { expectedRole: 'hospital-user' },
        loadChildren: () =>
            import('../../dashboard/common-ui/data-uploads/data-uploads.module').then(
                (m) => m.DataUploadsModule
            )
    },
    {
        path: 'vendor-management',
        data: { expectedRole: 'hospital-user' },
        loadChildren: () =>
            import('../../dashboard/stores/vendors/vendors.module').then(
                (m) => m.VendorsModule
            )
    },
];
