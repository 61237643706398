import { Component, OnInit } from '@angular/core';
import { ApiService } from '@services/api-service.service';
import { ApiConstants } from '@utils/api-constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isLoading: boolean = false;
  constructor( private apiService: ApiService) { }

  ngOnInit(): void {
  }
  getCustomerList() {
    this.apiService.parseApiCall(ApiConstants.URL.DASHBOARD_DETAILS.URI, ApiConstants.URL.DASHBOARD_DETAILS.METHOD, {
     
    }).subscribe((res: any) => {
      this.isLoading = false;
      if (res.success) {
      
        // this.list = res.data.customer_list;
        // this.listLength = res.data.pagination.total_items
      }
    });
  }

}
