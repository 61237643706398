import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalConfig } from '@models/modal-config';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-item-edit',
  templateUrl: './order-item-edit.component.html',
  styleUrls: ['./order-item-edit.component.scss']
})
export class OrderItemEditComponent implements OnInit {

  ngbModalRef!: NgbModalRef;
  @Output() success: EventEmitter<string> = new EventEmitter();
  @Input() public modalConfig!: ModalConfig
  @Input() public orderData!: any
  @Input() public itemData!: any
  @Input() public statusOptions!: any
  private modalRef!: NgbModalRef
  itemUpdateForm!: FormGroup;
  submitted: boolean = false;
  @ViewChild('orderItemEdit') private modalContent!: TemplateRef<OrderItemEditComponent>

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.itemUpdateForm = this.formBuilder.group({
      itemId: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      unitRate: [''],
      mrp: [''],
      sgst: [''],
      cgst: ['']
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['itemData']) {
      if (this.itemUpdateForm) {
        this.itemUpdateForm.patchValue({
          itemId: changes['itemData']['currentValue']['_id'],
          quantity: changes['itemData']['currentValue']['quantity'] || 0,
          unitRate: changes['itemData']['currentValue']['unitRate'] || 0,
          mrp: changes['itemData']['currentValue']['mrp'] || 0,
          sgst: changes['itemData']['currentValue']['sgst'] || 6,
          cgst: changes['itemData']['currentValue']['cgst'] || 6
        })
      }
    }
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  get f() {
    return this.itemUpdateForm.controls;
  }

  updateItem() {
    this.success.emit({
      ...this.itemUpdateForm.value,
      orderId: this.orderData._id
    })
  }
}
