import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.localStorageService.getItem('accessToken');
    if (token) {
      req = req.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + token,
        }
      })
    }
    return next.handle(req);
  }
}
