import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';

import { Observable, EMPTY, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '@services/api-service.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {

  constructor(
    private apiService: ApiService,
    private toaster: ToastrService,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          if ([401, 403].includes(error.status)) {
            // auto logout if 401 or 403 response returned from api
            this.apiService.doLogout();
          }

        }
        // If you want to return a new response:
        this.toaster.error(error.error.message)
        return of(new HttpResponse({body: {success: false, message: error.error.message}}));

        // If you want to return the error on the upper level:
        // return throwError(error);

        // or just return nothing:
        // return EMPTY;
      })
    );
  }
}
