import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '@models/modal-config';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @ViewChild('confirmModal', { static: true }) confirmModal!: ElementRef;
  ngbModalRef!: NgbModalRef;

  @Input() public modalConfig!: ModalConfig
  @ViewChild('confirmModal') private modalContent!: TemplateRef<ConfirmationModalComponent>
  private modalRef!: NgbModalRef
  @Output() confirmed: EventEmitter<any> = new EventEmitter();
  @Input() public data!: any;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && !changes['data'].firstChange) {
      this.data = changes['data'].currentValue
    }
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  modalConfirm() {
    this.confirmed.emit({ ...this.data })
  }
}
