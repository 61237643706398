import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { APIResponse } from '@models/api-response-data';
import { IFilterDataSearchItems } from '@models/filter-data';
import { ApiService } from '@services/api-service.service';
import { ApiConstants } from '@utils/api-constants';

@Component({
  selector: 'app-custom-mapping',
  templateUrl: './custom-mapping.component.html',
  styleUrls: ['./custom-mapping.component.scss']
})
export class CustomMappingComponent implements OnInit {
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Input() public filterItems!: IFilterDataSearchItems;
  @Input() public searchTerms!:any;
  @Input() public searchTerms1!:any;
  selectedOption = 'CUSTOMER';
  selectCustomer=false

  customerLists:any
 
  filterForm!: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
   this.customerList();
    console.log(this.filterItems)
    this.filterForm = this.formBuilder.group({
      searchByRole: [this.selectedOption,''],
      searchByData: [''],
      searchByName: [{ value: "", disabled: true },'']
    })
    this.filterForm.valueChanges.subscribe(data => {
      console.log(this.filterForm.value)
      this.filter.emit(this.filterForm.value)
    })
  }

  customerList() {
    this.apiService.parseApiCall(ApiConstants.URL.CUSTOM_HEADERS_CUSTOMER_LIST.URI, ApiConstants.URL.CUSTOM_HEADERS_CUSTOMER_LIST.METHOD, {
      
    }).subscribe((res: APIResponse) => {
      if (res.success) {
        console.log(res)
        this.customerLists = res.data;
      }
    });
  }
  changeValue(){
    // this.selectCustomer=true
    this.filterForm.get('searchByName')?.enable();
  }

}
